
import { motion } from "framer-motion";
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import CollectionsIcon from '@mui/icons-material/Collections';
import GitHubIcon from '@mui/icons-material/GitHub';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import LinkIcon from '@mui/icons-material/Link';
import MainTitle from './elements/MainTitle';
import ShowGallery from './elements/ShowGallery';

const myWorks: Array<{ name: string, description: string, img: string, githubUrl: string | null, onlineUrl: string | null, gallery?: { desktop: string[], mobile: string[] } }> = [
    // {
    //     name: 'PriseKyes',
    //     description: 'Description for Mockup 1',
    //     img: './priseKyes/home.jpeg',
    //     githubUrl: '',
    //     onlineUrl: 'https://prisekeys.com/',
    // },
    {
        name: 'mediapulse',
        description: 'Description for Mockup 1',
        img: './mediapulse/home.jpeg',
        githubUrl: '',
        onlineUrl: 'https://mediapulse.ma/',
    },
    {
        name: 'Clean crow',
        description: 'Description for Mockup 1',
        img: './clean crow/homepage.png',
        githubUrl: '',
        onlineUrl: null,
        gallery: {
            desktop: [
                "./clean crow/homepage.png",
                "./clean crow/About us.png",
                "./clean crow/contact.png",
                "./clean crow/Services.png",
                "./clean crow/Our Works.png",
                "./clean crow/pricing.png",
                "./clean crow/Blog.png",
            ],
            mobile: [
                "./clean crow/mobile/home.png",
                "./clean crow/mobile/about.png",
                "./clean crow/mobile/service.png",
                "./clean crow/mobile/ourWork.png",
                "./clean crow/mobile/Pricing.png",
                "./clean crow/mobile/blog.png",
                "./clean crow/mobile/contact.png",
                "./clean crow/mobile/Request.png",
                "./clean crow/mobile/menu.png",
            ]

        },
    },
    {
        name: 'HBS Agency',
        description: 'Description for Mockup 2',
        img: './hbs/Home.png',
        githubUrl: '',
        onlineUrl: '',
        gallery: {
            desktop: [
                "./hbs/Home.png",
                "./hbs/About.png",
                "./hbs/Service.png",
                "./hbs/Prices.png",
                "./hbs/Product.png",
                "./hbs/Contact V1.png",
            ],
            mobile: []

        },
    },
    {
        name: 'Apprentissage facile',
        description: 'Description for Mockup 3',
        img: './appFacile/home.jpeg',
        githubUrl: null,
        onlineUrl: 'https://apprentissagefacile.fr/',
        gallery: {
            desktop: [
                "./appFacile/home.jpeg",
                "./appFacile/formation.jpeg",
                "./appFacile/team.jpeg",
                "./appFacile/contact.jpeg",
            ],
            mobile: []
        },
    },
    {
        name: 'BeautyParadise',
        description: 'Description for Mockup 4',
        img: './httpsbeautyparadise.ma/home.jpeg',
        githubUrl: '',
        onlineUrl: 'https://beautyparadise.ma/',
    },
    {
        name: 'Tradimoon',
        description: 'Description for Mockup 8',
        img: './tradimoon/home.webp',
        githubUrl: 'https://github.com/MS-brahim/app_tradimoon_FE',
        onlineUrl: 'https://app.tradimoon.com/',
        gallery: {
            desktop: [
                "./tradimoon/home.webp",
                "./tradimoon/Apple Stocks.png",
                "./tradimoon/Apple Stocks-1.png",
                "./tradimoon/DASHBOARD.png",
                "./tradimoon/Desktop - 1.png",
                "./tradimoon/Desktop - 2.png",
                "./tradimoon/Desktop - 3.png",
                "./tradimoon/Stock Manager.png",
                "./tradimoon/Stock.png",
                "./tradimoon/Stocks.png",
                "./tradimoon/Stocks-1.png",
                "./tradimoon/Stocks-2.png",
            ],
            mobile: []
        },
    },
    {
        name: 'loft',
        description: 'Description for Mockup 8',
        img: './loft/Web 1280 – 1.png',
        githubUrl: null,
        onlineUrl: null,
        gallery: {
            desktop: [
                "./loft/Web 1280 – 1.png",
                "./loft/Web 1920 – 1.png",
                "./loft/Web 1920 – 2.png",
            ],
            mobile: [
                "./loft/mobile/iPhone X-XS-11 Pro – 1.png",
                "./loft/mobile/iPhone X-XS-11 Pro – 4.png",
                "./loft/mobile/iPhone X-XS-11 Pro – 5.png",
                "./loft/mobile/iPhone X-XS-11 Pro – 3.png",
            ]
        },
    },
    {
        name: 'CPF',
        description: 'Description for Mockup 8',
        img: './landing page/cpf.jpeg',
        githubUrl: null,
        onlineUrl: "https://moncompteformation.online/",
        gallery: {
            desktop: [
                "./landing page/cpf.jpeg",
            ],
            mobile: []
        },
    },
    {
        name: 'esa',
        description: 'Description for Mockup 8',
        img: './esa/Screenshot_23-7-2024_141513_expertsoinauto.com.jpeg',
        githubUrl: null,
        onlineUrl: "https://moncompteformation.online/",
        gallery: {
            desktop: [
                "./esa/Screenshot_23-7-2024_14176_expertsoinauto.com.jpeg",
                "./esa/Screenshot_23-7-2024_141513_expertsoinauto.com.jpeg",
                "./esa/Screenshot_23-7-2024_141532_expertsoinauto.com.jpeg",
                "./esa/Screenshot_23-7-2024_141551_expertsoinauto.com.jpeg",
                "./esa/Screenshot_23-7-2024_141620_expertsoinauto.com.jpeg",
                "./esa/Screenshot_23-7-2024_141646_expertsoinauto.com.jpeg",
            ],
            mobile: []
        },
    },
    {
        name: 'Sosoon',
        description: 'Description for Mockup 8',
        img: './sosoon/Screenshot_28-9-2024_234437_sosoon.io.jpeg',
        githubUrl: null,
        onlineUrl: "https://sosoon.io/",
    },
    {
        name: 'Live4us Academy',
        description: 'Description for Mockup 7',
        img: './live4us/Desktop - 2.png',
        githubUrl: null,
        onlineUrl: null,
        gallery: {
            desktop: [
                "./live4us/Desktop - 2.png",
                "./live4us/Desktop - 3.png",
                "./live4us/Desktop - 4.png",
                "./live4us/Desktop - 5.png",
                "./live4us/Desktop - 6.png",
                "./live4us/Desktop - 7.png",
            ],
            mobile: []
        },
    },
    {
        name: 'MARINE',
        description: 'Description for Mockup 6',
        img: './la marine/HOME.png',
        githubUrl: '',
        onlineUrl: null,
        gallery: {
            desktop: [
                "./la marine/HOME.png",
                "./la marine/GALLERY.png",
                "./la marine/COURS ARRCHIVE.png",
                "./la marine/COURS DETAIL.png",
                "./la marine/CONTACT.png",
                "./la marine/CLUB.png",
                "./la marine/PROFIL.png",
            ],
            mobile: [
                "./la marine/mobile/HOME.png",
                "./la marine/mobile/Gallery.png",
                "./la marine/mobile/COURS ARRCHIVE.png",
                "./la marine/mobile/COURS DETAIL.png",
                "./la marine/mobile/Contact.png",
                "./la marine/mobile/CLub.png",
                "./la marine/mobile/Profil.png",
            ]
        },
    },

];


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1a202724' : '#ffffff40',
    ...theme.typography.body2,
    borderRadius: 0,
    color: theme.palette.text.secondary,
    // marginBottom: 5,
    transition: 'background-color 0.3s ease',
    position: 'relative',
    maxHeight: 300,
    overflowY: 'auto',
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
        width: '4px',  // Width of the scrollbar
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#888',  // Color of the scrollbar thumb
        borderRadius: '2px',  // Rounded edges for the scrollbar thumb
    },
    '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#555',  // Darken the thumb on hover
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: '#f1f1f1',  // Track color
    },
    // Firefox scrollbar customization
    scrollbarWidth: 'thin',  // Thin scrollbar in Firefox
    scrollbarColor: '#001c49 #f1f1f1',
}));


type ProjectComponentProps = {}

const ProjectComponent: React.FC<ProjectComponentProps> = () => {
    const [open, setOpen] = React.useState(false);
    const [gallery, setGallery] = React.useState([]);

    const handleOpenGallery = React.useCallback((gallery: any) => {
        setGallery(gallery);
        setOpen(true);
    }, [setGallery, setOpen]);

    return (
        <div>
            {open &&
                <ShowGallery data={gallery} open={open} setOpen={setOpen} />
            }
            <MainTitle title='Projects' />
            <Stack spacing={{ xs: 2, sm: 2 }} direction="row" justifyContent='center' useFlexGap flexWrap="wrap">
                {
                    myWorks.map((work: any, index: number) => (
                        <motion.div key={index}
                        className="responsive-item"
                            initial={{ opacity: 0, y: -20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}>
                            <Box sx={{
                                border: '0.5px solid #dfdfdf3b',
                                display: 'flex',
                                backgroundColor: '#001c49',
                                justifyContent: 'center',
                                borderTopLeftRadius:20,
                                borderTopRightRadius:20
                            }}>
                                {
                                    work.githubUrl &&
                                    <IconButton>
                                        <GitHubIcon sx={{ color: '#fff' }} />
                                    </IconButton>
                                }
                                {work.onlineUrl &&
                                    <IconButton href={work.onlineUrl} target='_blank'>
                                        <LinkIcon sx={{ color: '#fff' }} />
                                    </IconButton>
                                }
                                {work.gallery &&
                                    <IconButton onClick={() => handleOpenGallery(work.gallery)}>
                                        <CollectionsIcon sx={{ color: '#fff' }} />
                                    </IconButton>
                                }
                            </Box>
                            <Item>
                                <Avatar sx={{ width: '100%', height: 'auto' }} variant="square" src={work.img} alt="" />
                            </Item>
                        </motion.div>

                    ))
                }
                {/* </Stack> */}
            </Stack>
        </div>
    );
}


export default ProjectComponent;
