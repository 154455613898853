import * as React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import MainTitle from './elements/MainTitle';
import { motion } from "framer-motion";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1a202738' : '#ffffff6e',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    // maxWidth: 240,
    maxHeight: 400,
    marginTop: 2
}));


const services = [
    {
        "title": "Custom Website Development",
        "description": [
            "Tailored Solutions: I create fully customized websites that cater to your specific requirements and brand identity.",
            "Responsive Design: Ensure your website looks great on all devices, from desktops to mobile phones."
        ]
    },
    {
        "title": "E-commerce Development",
        "description": [
            "E-commerce Platforms: I build robust e-commerce websites that enhance user experience and drive sales.",
            "Payment Integration: Seamlessly integrate payment gateways to provide a secure and efficient shopping experience."
        ]
    },
    {
        "title": "Content Management Systems (CMS)",
        "description": [
            "User-Friendly CMS: I develop websites using popular CMS platforms (e.g., WordPress) to allow easy content management.",
            "Custom Themes and Plugins: Tailor themes and develop custom plugins to extend functionality."
        ]
    },
    {
        "title": "API Development and Integration",
        "description": [
            "RESTful APIs: Create and integrate APIs to enable smooth communication between different services and applications.",
            "Third-Party Integrations: Connect your application with various third-party services (e.g., payment processors, social media)."
        ]
    },
    {
        "title": "Frontend Development",
        "description": [
            "Modern Frameworks: I utilize modern frontend frameworks (e.g., React, Vue.js) to create interactive and dynamic user interfaces.",
            "UI/UX Design: Focus on creating user-friendly and visually appealing designs to enhance user engagement."
        ]
    },
    {
        "title": "Backend Development",
        "description": [
            "Database Management: Implement efficient database solutions (e.g., MySQL, MongoDB) to store and manage data securely.",
            "Server-Side Logic: Develop robust server-side applications using technologies such as Node.js, Express, or Laravel."
        ]
    },
    {
        "title": "Website Maintenance and Support",
        "description": [
            "Ongoing Support: Provide regular maintenance, updates, and technical support to ensure your website runs smoothly.",
            "Performance Optimization: Enhance website performance and speed for a better user experience."
        ]
    },
    {
        "title": "Consultation and Strategy",
        "description": [
            "Project Planning: Collaborate with you to understand your goals and provide strategic guidance on your web projects.",
            "Digital Strategy: Help you develop a digital strategy that aligns with your business objectives."
        ]
    }
]




type ServiceComponentProps = {}

const ServiceComponent: React.FC<ServiceComponentProps> = () => {
    return (
        <Box style={{ textAlign: 'center' }}>
            <MainTitle title='Services' />
            <Typography variant='subtitle2' gutterBottom>
                Comprehensive Web Development Solutions
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                As a full-stack web developer, I offer a wide range of services to meet your digital needs. Here’s what I can provide:
            </Typography>
            <br />
            <Stack spacing={{ xs: 2, sm: 2 }} direction="row" justifyContent='center' useFlexGap flexWrap="wrap">

                {services.map((service, index) =>
                    <Item sx={{ width: { xs: '100%', sm: '45%', md: '30%' } }}>
                        <motion.div key={index}
                            initial={{ opacity: 0, y: -20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}>

                            <Typography align='center' variant="h6" gutterBottom>
                                {service.title}
                            </Typography>
                            <div>
                                {service.description.map((desc, descIndex) => (
                                    <p key={descIndex}>
                                        👉 <span>{desc}</span>
                                    </p>
                                ))}
                            </div>
                        </motion.div>
                    </Item>
                )}
            </Stack>
        </Box>
    );
}


export default ServiceComponent;