import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import { useState } from 'react';
import MainTitle from './elements/MainTitle';

export default function ContactComponent() {
    const [submiting, setSubmitting] = useState(false);
    const [storeGS, setStoreGS] = useState(false);
    const [fullname, setFullname] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({ fullname: "", email: "" });

    const validateField = (field: string, value: string) => {
        let tempErrors = { ...errors };
        let isValid = true;

        switch (field) {
            case 'fullname':
                if (value.trim() === "") {
                    tempErrors.fullname = "Full name is required.";
                    isValid = false;
                } else {
                    tempErrors.fullname = "";
                }
                break;
            case 'email':
                if (value.trim() === "") {
                    tempErrors.email = "Email address is required.";
                    isValid = false;
                } else if (!/\S+@\S+\.\S+/.test(value)) {
                    tempErrors.email = "Email address is invalid.";
                    isValid = false;
                } else {
                    tempErrors.email = "";
                }
                break;
            default:
                break;
        }

        setErrors(tempErrors);
        return isValid;
    };

    const handleInputChange = (setter: any, field: string) => (event: any) => {
        const value = event.target.value;
        setter(value);
        validateField(field, value);
    };

    const handleSubmit = async () => {
        // Validate fields before proceeding
        if (!validateField('fullname', fullname) || !validateField('email', email)) return;
        setSubmitting(true);
        // Collect input values dynamically
        const formData = new FormData();
        formData.append("fullname", fullname);  // Using the actual state values
        formData.append("email", email);  // Using the actual state values
        formData.append("message", message);  // Using the actual state values
        const Sheet_Url = "https://script.google.com/macros/s/AKfycbyr5UPtOStgyPKDd2-XI7-Vg7ZI4eLEFFupRXO9ZrCSNrbgg0vfPT41b3vCXma0Ow_vhg/exec"

        try {


            const response = await fetch(Sheet_Url, {
                method: 'POST',
                body: formData,
                // muteHttpExceptions: true,
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            console.log(data);
            if (data.result === "success") {
                setStoreGS(true);
                setFullname("");
                setEmail("");
                setMessage("");
                setTimeout(() => {
                    setStoreGS(false);
                }, 3000);
            }

        } catch (error) {
            console.log(error);
        } finally {
            setSubmitting(false)
        }
    }


    return (
        <div>
            <MainTitle title='Contact' />

            <Typography align='center' variant='h6' gutterBottom>
                Connect with me
            </Typography>
            <Typography sx={{ maxWidth: 450, mx: 'auto' }} align='center' variant="subtitle2" display="block" gutterBottom>
                Have questions, need advice, or looking to build your website? <br/><strong>Let’s talk! 👇</strong> 
            </Typography>
            {storeGS
                ?
                <Stack sx={{ mt: 3, mx: 'auto', minHeight: 300 }} alignItems="center" justifyContent="center">

                    <Typography sx={{ fontSize: 80, margin: 0 }}>
                        🤩
                    </Typography>
                    <Typography align='center' variant='subtitle2' gutterBottom>
                        We appreciate your message!
                    </Typography>
                    <Typography align='center' variant="caption" display="block" gutterBottom>
                        Your message has been sent successfully! We will get back to you shortly.
                    </Typography>
                </Stack>
                :
                <Stack sx={{ mt: 3, mx: 'auto', minHeight: 300 }} flexDirection='column' justifyContent='center' maxWidth={500}>
                    <TextField
                        sx={{ mt: 3 }}
                        variant="standard"
                        id="name"
                        label="Full name"
                        value={fullname}
                        onChange={handleInputChange(setFullname, 'fullname')}
                        placeholder='ex: Brahim MOUISSI'
                        error={!!errors.fullname}
                        helperText={errors.fullname}
                    />
                    <TextField
                        sx={{ mt: 3 }}
                        variant="standard"
                        id="email"
                        label="Email Address"
                        value={email}
                        onChange={handleInputChange(setEmail, 'email')}
                        placeholder='example@example.com'
                        error={!!errors.email}
                        helperText={errors.email}
                    />
                    <TextField
                        sx={{ mt: 3 }}
                        variant="standard"
                        multiline
                        maxRows={10}
                        rows={4}
                        id="message"
                        label="Message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder='Hello ...'
                    />
                    <Button
                        onClick={handleSubmit}
                        disabled={submiting}
                        sx={{ my: 4, height: 50 }} variant="contained">
                        {submiting
                            ?
                            <div> <span className="loader"></span></div>
                            :
                            'Submit'
                        }
                    </Button>
                </Stack>
            }
        </div>
    )
}
