import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#dfdfdf',
    },
    primary: {
      main: '#001c49', 
      contrastText: '#fff',
    },
    secondary: {
      main: '#ff4081',
      contrastText: '#fff',
    },
  },
  shape: {
    borderRadius: 20,
  },
  components: {
    MuiButton : {
      styleOverrides: {
        root: {
          padding: 15,
          textTransform: 'capitalize',
          fontWeight: 'bold',
        }
      }
    }
  }
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark', // Enable dark mode
    background: {
      default: '#001c49', // Dark background color
    },
    primary: {
      main: '#ffc100', // Primary color for dark mode
      contrastText: '#000',
    },
    secondary: {
      main: '#03dac6', // Secondary color for dark mode
      contrastText: '#000',
    },
  },
  shape: {
    borderRadius: 20,
  },
  components: {
    MuiButton : {
      styleOverrides: {
        root: {
          padding: 15,
          textTransform: 'capitalize',
          fontWeight: 'bold',
          color: '#fff'
        }
      }
    }
  }
});

export { lightTheme, darkTheme };
