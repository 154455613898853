import Typography from '@mui/material/Typography';

type MainTitleProps = {
    title:string;
}

const MainTitle:React.FC<MainTitleProps> = ({title}) => {
    return (
        <Typography  sx={{ width: '100%', mb:3, fontWeight:800 }} align='center' variant="h4" gutterBottom>
            {title}
        </Typography>
    );
}
 
 
export default MainTitle;