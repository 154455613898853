import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { motion } from "framer-motion";

export default function HeroComponent() {

    const scrollToTarget = () => {
        const targetElement = document.querySelector('#contactForm');
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6, sm: 6, md: 12 }} sx={{ alignItems: 'center' }}>
            <Grid item xs={12} sm={6} md={6}>
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}>
                    <Stack spacing={2}>
                        <Typography sx={{ width: '100%', fontWeight: 600 }} variant="h4" gutterBottom>
                            I'm Brahim MOUISSI
                        </Typography> <br />
                        <Typography sx={{ width: '100%' }} variant="h6" gutterBottom>
                            Full Stack Developer | Creating Seamless User Experiences and Robust Backend Systems
                        </Typography>
                        <br />
                        <Stack direction="row" spacing={1} flexWrap={"wrap"}>
                            <IconButton aria-label="GitHubIcon" sx={{ color: '#6e5494' }}
                                target='_blank'
                                component="a"
                                href='https://github.com/MS-brahim'
                                rel="noopener noreferrer"
                            >
                                <GitHubIcon />
                            </IconButton>
                            <IconButton aria-label="TwitterIcon" sx={{ color: '#1da1f2' }}
                                target='_blank'
                                component="a"
                                href='https://x.com/brahimmouissi'
                                rel="noopener noreferrer"
                            >
                                <TwitterIcon />
                            </IconButton>
                            <IconButton aria-label="TelegramIcon" sx={{ color: '#0088cc' }}
                                target='_blank'
                                component="a"
                                href='https://t.me/brahimmouissi'
                                rel="noopener noreferrer"
                            >
                                <TelegramIcon />
                            </IconButton>
                            <IconButton aria-label="WhatsAppIcon" sx={{ color: '#25d366' }}
                                target='_blank'
                                component="a"
                                href='https://wa.me/+212607279713'
                                rel="noopener noreferrer"
                            >
                                <WhatsAppIcon />
                            </IconButton>
                            <IconButton aria-label="LinkedInIcon" sx={{ color: '#0a66c2' }}
                                target='_blank'
                                component="a"
                                href='https://www.linkedin.com/in/brahimouissi/'
                                rel="noopener noreferrer"
                            >
                                <LinkedInIcon />
                            </IconButton>
                            <IconButton aria-label="GoogleIcon" sx={{ color: '#4285f4' }}
                                component="a"
                                href="mailto:brahim7khalil@gmail.com?subject=Hello&body=I would like to discuss..."
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <GoogleIcon />
                            </IconButton>
                        </Stack>

                        <Button
                            onClick={scrollToTarget}
                            sx={{ maxWidth: { xs: 300, sm: '50%' }, mx: { sm: 'auto' } }} variant="contained">
                            Let me a Message
                        </Button>
                    </Stack>
                </motion.div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
                <img
                    src='./hero_image.png'
                    alt="Brahim MOUISSI"
                    className="slide-right"
                    width={"100%"}
                />
            </Grid>

        </Grid>
    )
}
