import Container from '@mui/material/Container';
import HeroComponent from './HeroComponent';
import SkillsComponent from './SkillsComponent';
import ContactComponent from './ContactComponent';
import ServiceComponent from './ServicesComponent';
import ProjectComponent from './PorjectComponent';

export default function Main() {
    return (
        <Container maxWidth="lg">
            <HeroComponent />
            {/* <div id='about' className='section'>
            <AboutComponent />
            </div> */}
            <div id='skills' className='section'>
            <SkillsComponent />
            </div>
            <div id='service' className='section'>
            <ServiceComponent/>
            </div>
            <div id='projects' className='section'>
            <ProjectComponent/>
            </div>
            {/* <br/>
            <br/>
            <ExperienceComponent />
            <br/>
            <br/>
            <FormationComponent /> */}
            <div id='contactForm' className='section'>
            <ContactComponent />
            </div>
        </Container>
    )
}
