import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

type FooterProps = {}

const Footer: React.FC<FooterProps> = () => {
    const currentYear = new Date().getFullYear();
    const [visible, setVisible] = useState<boolean>(false);
    const toggleVisibility = () => {
        if (window.scrollY > 300) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <Box
            sx={{
                padding: '30px 16px',
                textAlign: 'center',
            }}
        >
            <Typography variant="body2" color="textSecondary">
                &copy; {currentYear} All Rights Reserved | Made by Brahim MOUISSI
            </Typography>
            {visible && (
                <Fab
                size='medium'
                    onClick={scrollToTop}
                    color="primary"
                    aria-label="scroll back to top"
                    sx={{ position: 'fixed', bottom: 16, right: 16 }}
                >
                    <KeyboardArrowUpIcon />
                </Fab>
            )}
        </Box>
    );
}


export default Footer;