import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import MainTitle from './elements/MainTitle';
import { motion } from "framer-motion";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    paddingRight: '18px',
    color: theme.palette.text.secondary,
    gap: theme.spacing(1),
    marginTop: 2,
    width: '45%',
    [theme.breakpoints.up('sm')]: {
        width: 200, // Width for screen sizes 'sm' and up
    },
}));

const data = [
    { name: "JavaScript", url: "https://img.icons8.com/fluency/48/javascript.png", link: "https://www.javascript.com" },
    { name: "Php", url: "https://img.icons8.com/fluency/48/php.png", link: "https://www.php.net" },
    { name: "Laravel", url: "https://img.icons8.com/fluency/48/laravel.png", link: "https://laravel.com/" },
    { name: "Node", url: "https://img.icons8.com/color/48/nodejs.png", link: "https://nodejs.org" },
    { name: "React", url: "https://img.icons8.com/offices/30/react.png", link: "https://react.dev/" },
    { name: "NextJS", url: "./nextjs-icon.svg", link: "https://nextjs.org/" },
    { name: "TypeScript", url: "./ts.png", link: "https://www.typescriptlang.org/" },
    { name: "React Native", url: "https://img.icons8.com/offices/30/react.png", link: "https://reactnative.dev/" },
    { name: "Angular", url: "https://img.icons8.com/color/48/angularjs.png", link: "https://angular.dev/" },
    { name: "Vue", url: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Vue.js_Logo_2.svg/1024px-Vue.js_Logo_2.svg.png?20170919082558", link: "https://vuejs.org/" },
    { name: "Html5", url: "https://img.icons8.com/color/48/html-5--v1.png", link: "https://html.com/" },
    { name: "CSS3", url: "https://img.icons8.com/stickers/100/css3.png", link: "https://css3.com/" },
    { name: "Sass", url: "https://img.icons8.com/color/48/sass.png", link: "https://css3.com/" },
    { name: "Bootstrap", url: "./Bootstrap_logo.png", link: "https://getbootstrap.com/docs/5.0/about/brand/" },
    { name: "MySQL", url: "https://img.icons8.com/fluency/48/my-sql.png", link: "https://css3.com/" },
    { name: "MongoDB", url: "https://img.icons8.com/color/48/mongodb.png", link: "https://www.mongodb.com/" },
    { name: "Redis", url: "https://driftt.imgix.net/https%3A%2F%2Fdriftt.imgix.net%2Fhttps%253A%252F%252Fs3.us-east-1.amazonaws.com%252Fcustomer-api-avatars-prod%252F141897%252F52c45cd3cc5e5d43bd595156f83e651bx2yzszhb3wdg%3Ffit%3Dmax%26fm%3Dpng%26h%3D200%26w%3D200%26s%3D0e42729d4c11e29e5bc38f7a564bab43?fit=max&fm=png&h=200&w=200&s=f01888d086cbc35d0da1cec5ec80242a", link: "https://redis.io/" },
    { name: "jQuery", url: "https://img.icons8.com/external-tal-revivo-color-tal-revivo/24/external-jquery-is-a-javascript-library-designed-to-simplify-html-logo-color-tal-revivo.png", link: "https://jquery.com/" },
    { name: "Java", url: "https://img.icons8.com/fluency/48/java-coffee-cup-logo.png", link: "https://www.java.com/" },
    { name: "GitHub", url: "https://img.icons8.com/fluency/48/github.png", link: "https://www.github.com/" },
    { name: "GIT", url: "https://img.icons8.com/color/48/git.png", link: "https://www.java.com/" },
    { name: "Ubuntu", url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAclBMVEXpVCDpUBboTQ/oSQDpUx3oRgDtfGDxnov1vrXpWSzseFv53tr99PP52dPtgGf++Pf////3ysLrZ0P87uvzsqXui3TvlYHyq5vqYDjnQADxo5PoTAn64NvpURnrb0zqZ0H40cr75+P0uK3vkXzuhm741M8T8wJ+AAABD0lEQVR4AbWRRZbEMAwFZVA48Q+j0nz/Iw4EGtddO7+ymL6P0sZYxczqUynPD4IwipM0erNWZakD8qIEkJB9cVwBgKt9/NPoZ8k5kLRdz8Mqx+d6PjAVo5DMJQBHB3kkHaPhWCypVHZyk5elod2ckH+ue7UlYcMH4KK3OJoA5PLo0DjkTAt67TB7DMdHlJscz+/tmwqJ2SLrZcRIHvKMhGmzgYMbnubmFKddWvHqebT7y1oBAkMbUlTHZn0Jd9L7wFXt0nYlEu/fqjmFO4+n9GkwfQXKq9aiUwDws5ejLUfJ63B2ABD3Qs9wkwA4hYu8Mb2iiqAEKADgtm6eEHO4+jO1p7TW9IkoLXZk1vRdfgHIfhK5WQ400AAAAABJRU5ErkJggg==", link: "https://ubuntu.com/" },
    { name: "python", url: "https://img.icons8.com/fluency/48/python.png", link: "https://www.python.org/" },
    { name: "WordPress", url: "https://img.icons8.com/?size=100&id=13664&format=png&color=000000", link: "https://wordpress.com/" },
    { name: "Elementor", url: "./Elementor-Logo-Symbol-Red.png", link: "https://elementor.com/" },
]
export default function SkillsComponent() {
    return (
        <>
            <MainTitle title='Technologies and Tools' />

            <Stack spacing={{ xs: 2, sm: 2 }} direction="row" justifyContent='center' useFlexGap flexWrap="wrap">
                {data.map((item: any, index: number) =>

                    <Item key={index}>
                        <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                            style={{display:'flex', alignItems:'center'}}>
                            <Avatar
                                component="a" href={item.link}
                                target='_blank'
                                rel="noopener noreferrer"
                                alt={item.name} src={item.url} > {item.name} </Avatar>
                            <Typography variant='body2' sx={{ml:1}}>
                                {item.name}
                            </Typography>
                        </motion.div>
                    </Item>
                )}
            </Stack>
        </>
    )
}
