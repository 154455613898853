import * as React from 'react';
import './showGallery.css';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Swiper, SwiperSlide } from 'swiper/react';
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
// Import Swiper styles
import 'swiper/css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        backgroundColor: theme.palette.background.default,
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

type ShowGalleryProps = {
    open: boolean | false,
    setOpen: (value: boolean) => void,
    data: any;

}


const ShowGallery: React.FC<ShowGalleryProps> = ({ open, setOpen, data }) => {

    const [images, setImages] = React.useState(Array.isArray(data.desktop) ? data.desktop : [])
    const [device, setDevice] = React.useState<string>('desktop');

    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        setImages(data.desktop);
    }, [data.desktop]);

    const handleSwitchDevice = (device: any) => {
        setImages(data[device]);
        setDevice(device);
    };

    const renderIcons = () => (
        <React.Fragment>
            <IconButton
                aria-label="Switch to mobile view"
                onClick={() => handleSwitchDevice('mobile')} sx={{ background: device === 'mobile' ? '#ffffff94' : '' }}>
                <StayCurrentPortraitIcon />
            </IconButton>
            <IconButton
                aria-label="Switch to desktop view"
                onClick={() => handleSwitchDevice('desktop')} sx={{ background: device === 'desktop' ? '#ffffff94' : '' }}>
                <PersonalVideoIcon />
            </IconButton>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <BootstrapDialog
                fullScreen
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 1 }} id="customized-dialog-title">
                    {renderIcons()}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        zIndex: 122,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        style={{ width: '100%', height: 'auto' }}
                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper: any) => console.log(swiper)}
                    >
                        {images.map((img: string, index: number) => (
                            <SwiperSlide key={index} className={device}>
                                <img width={'100%'} height='auto' src={img} alt={img} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </DialogContent>
            </BootstrapDialog>
        </React.Fragment>
    );
}


export default ShowGallery;