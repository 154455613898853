import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Main from './components/Main';
import { CssBaseline, ThemeProvider as MUIThemeProvider } from '@mui/material';
import Footer from './components/Footer';
import { lightTheme, darkTheme } from './theme/createTheme';
import { useTheme, ThemeProvider } from './theme/ThemeContext';

const InnerApp = () => {
  const { isDarkMode } = useTheme();

  return (
    <MUIThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <Navbar main={<Main />} />
      <Footer />
    </MUIThemeProvider>
  );
};

export default function App() {
  return (
    <ThemeProvider>
      <InnerApp />
    </ThemeProvider>
  );
}
